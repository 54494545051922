<template>
    <div>
        <el-collapse v-loading="$waiting.is('loading')">
            <el-collapse-item v-for="(group, index) in form" :key="index" :name="group.id">
                <template slot="title">
                    <span :style="{ color: group.isInactive ? inactiveColor : group.icon.color }" class="p-2 ml-4" style="margin-bottom: 2px;font-size: 15px;">
                        <i :class="group.icon.code" />
                    </span>
                    <div class="flex justify-between w-full">
                        <div class="font-semibold text-sm" :class="{ 'inactive-color': group.isInactive }">{{ group.name }}</div>
                        <div class="flex">
                            <i class="el-icon-edit mr-2" @click="editGroupCategory(group)" />
                            <i @click="deleteAccountGroupCategory(group)" class="el-icon-delete" v-if="group.childList.length == 0" />
                            <el-switch v-if="group.isInactive" v-model="group.isInactive" :active-color="inactiveColor" :inactive-color="activeColor" disabled />
                        </div>
                    </div>
                </template>

                <el-row class="p-1 ml-6" v-for="(category, index) in group.childList" :key="index" :name="category.name">
                    <el-col :span="8" class="border-b" :class="{ 'inactive-color': category.isInactive }">{{ category.name }}</el-col>
                    <el-col :span="8" class="border-b" :class="{ 'inactive-color': category.isInactive }">{{ category.accountPlanName }}</el-col>
                    <el-col :span="2" class="border-b" :class="{ 'inactive-color': category.isInactive }">{{ category.vat }}</el-col>
                    <el-col :span="3" class="border-b">
                        <i v-if="!group.isInactive" class="fa-light fa-pen mr-3 cursor-pointer" @click="editCategory(category, group)" title="Edit" />
                        <i v-if="!group.isInactive" class="fal mr-3 fa-trash cursor-pointer" @click="deleteAccountCategory(category)" title="Radera" />
                        <el-switch v-if="category.isInactive" class="mb-px" v-model="category.isInactive" :active-color="inactiveColor" :inactive-color="activeColor" disabled />
                    </el-col>
                </el-row>
                <el-button v-if="!group.isInactive" @click="addAccountCategory(group)" class="ml-6 mt-5">Lägg till kategori</el-button>
            </el-collapse-item>
        </el-collapse>
        <AddAccountCategory :visible="showAddCategory" @close="showAddCategory = false" @reloadList="reloadList" :category-details="selectedCategory" :group-details="selectedAccountGroup" />
        <EditAccountGroupCategory :icons="iconOptions" :visible="showEditGroupCategory" @close="showEditGroupCategory = false" @reloadlist="reloadList" :account-group="selectedAccountGroup" />
    </div>
</template>
<script>
import Api from "../accountCategories.api";
import { Switch } from "element-ui";

export default {
    props: {
        categoryData: {
            type: Array,
            default: () => [],
        },
    },

    watch: {
        categoryData: {
            immediate: true,
            async handler() {
                this.form = this.categoryData.map(obj => ({ ...obj, isActive: false }));
            },
        },
    },

    data() {
        return {
            form: [],
            showEditGroupCategory: false,
            selectedAccountGroup: {},
            showAddCategory: false,
            selectedCategory: {},
            iconOptions: [],
            inactiveColor: "#cccccc",
            activeColor: "#13ce66",
        };
    },

    components: {
        "el-switch": Switch,
        AddAccountCategory: () => import(/* webpackChunkName: "AddAccountCategory" */ "../components/modals/AddAccountCategory.vue"),
        EditAccountGroupCategory: () => import(/* webpackChunkName: "AddAccountGroupCategory" */ "../components/modals/AddAccountGroupCategory.vue"),
    },

    methods: {
        async getIconOptions() {
            this.$waiting.start("loading");
            this.iconOptions = await Api.getIconOptions();
            this.$waiting.end("loading");
        },

        editGroupCategory(group) {
            this.getIconOptions();
            this.selectedAccountGroup = group;
            this.showEditGroupCategory = true;
        },

        editCategory(category, group) {
            this.selectedAccountGroup = group;
            this.selectedCategory = category;
            this.showAddCategory = true;
        },

        addAccountCategory(group) {
            this.selectedAccountGroup = group;
            this.selectedCategory = {};
            this.showAddCategory = true;
        },

        reloadList() {
            this.showAddCategory = false;
            this.showEditGroupCategory = false;
            this.$emit("reloadList");
        },

        handlePopoverShow(val) {
            this.selectedAccountGroup = val;
        },

        async deleteAccountCategory(category) {
            const confirm = await this.$dialog.title("Ta bort kontokategori " + category.name).confirm("Är du säker att du vill ta bort kategori - " + category.name + " ?");
            if (!confirm) return;
            await Api.deleteAccountCategory(category.id);
            this.$emit("reloadList");
        },

        async deleteAccountGroupCategory(group) {
            const confirm = await this.$dialog.title("Ta bort kategorigrupp " + group.name).confirm("Är du säker att du vill ta bort kategori - " + group.name + " ?");
            if (!confirm) return;
            await Api.deleteAccountGroupCategory(group.id);
            this.$emit("reloadList");
        },
    },
};
</script>

<style>
.el-collapse-item__arrow {
    position: absolute;
    left: 1%;
}
.inactive-color {
    color: #cccccc;
}
</style>
